export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  const cRegExp = /^(?:3[47][\d]{13})$/
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  const re = /^(http[s]?:\/\/){?}(www\.){?}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{?}/
  return re.test(val)
}
